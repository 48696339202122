import React, { useEffect, useState, useMemo } from "react";
import LineChart from './line_chart';
import axios from "../../../../lib/axios";
import moment from "moment";
import Loading from '../../spinners/loading';

const CostTrend = ({ from, to, months }) => {
  const [foodSeriesData, setFoodSeriesData] = useState(null);
  const [drinkSeriesData, setDrinkSeriesData] = useState(null);
  const [monthRange, setMonthRange] = useState(6);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get('/detail_reports/fd_data', { params: { from, to, month_range: monthRange, series: true } }).then((response) => {
      setFoodSeriesData(response.data.food_series);
      setDrinkSeriesData(response.data.drink_series);
      setLoading(false);
    });
  }, []);

  const labels = useMemo(() => {
    return months.slice(-monthRange).map((month) => {
      return moment(month).format('YYYY/MM');
    });
  }, [monthRange]);

  const rate = (cost, sales) => {
    if (!cost) {
      return 0.0;
    }
    if (sales <= 0) {
      return 0.0;
    }

    // 小数点1位まで
    return Math.round((cost / sales) * 1000) / 10;
  };

  const foodSeries = useMemo(() => {
    return months.map((month) => {
      let cost = (foodSeriesData && foodSeriesData.cost && foodSeriesData.cost[month]) ? foodSeriesData.cost[month].cost : 0;
      cost = cost + (foodSeriesData && foodSeriesData.petty_cache && foodSeriesData.petty_cache[month]) ? foodSeriesData.petty_cache[month].cost : 0;
      cost = cost + (foodSeriesData && foodSeriesData.stocking_transfer && foodSeriesData.stocking_transfer[month]) ? foodSeriesData.stocking_transfer[month].cost : 0;
      const sales = (foodSeriesData && foodSeriesData.sales && foodSeriesData.sales[month]) ? foodSeriesData.sales[month].sales : 0;
      return rate(cost, sales);
    });
  }, [drinkSeriesData]);

  const drinkSeries = useMemo(() => {
    return months.map((month) => {
      let cost = (drinkSeriesData && drinkSeriesData.cost && drinkSeriesData.cost[month]) ? drinkSeriesData.cost[month].cost : 0;
      cost = cost + (drinkSeriesData && drinkSeriesData.petty_cache && drinkSeriesData.petty_cache[month]) ? drinkSeriesData.petty_cache[month].cost : 0;
      cost = cost + (drinkSeriesData && drinkSeriesData.stocking_transfer && drinkSeriesData.stocking_transfer[month]) ? drinkSeriesData.stocking_transfer[month].cost : 0;
      const sales = (drinkSeriesData && drinkSeriesData.sales && drinkSeriesData.sales[month]) ? drinkSeriesData.sales[month].sales : 0;
      return rate(cost, sales);
    });
  }, [drinkSeriesData]);

  const handleMonthRangeChange = (e) => {
    setMonthRange(e.target.value);

    const newMonthRange = e.target.value;

    setLoading(true);
    axios.get('/detail_reports/fd_data', { params: { from, to, month_range: newMonthRange, series: true } }).then((response) => {
      setFoodSeriesData(response.data.food_series);
      setDrinkSeriesData(response.data.drink_series);
      setLoading(false);
    });
  }

  return (
    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
      <div className="card">
        <div className="card-body d-flex justify-content-between flex-column pb-0 px-0 pt-10">
          <div className="px-9">
            <div className="pb-2">
              <div className="d-flex flex-column pb-0 gap-4 align-items-start">
                <div className="d-flex">
                  <div className="symbol symbol-30px me-4">
                    <span className="symbol-label bg-light-primary">
                      <span className="svg-icon svg-icon-2 svg-icon-primary">
                        <span className="section-icon">
                          <i className="ki-duotone ki-graph-4">
                            <i className="path1"></i>
                            <i className="path2"></i>
                          </i>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
                    <div className="me-5">
                      <span className="text-gray-800 fw-bolder fs-5">FD仕入率トレンド</span>
                    </div>
                  </div>
                </div>
                <div className="header-action-area mt-2">
                  <div className="header-action justify-content-end">
                    <div className="text-gray-600 fs-8 me-3 nowrap">表示データ</div>
                    <select name="month_range_change" className="form-select py-2 w-auto" value={monthRange} onChange={handleMonthRangeChange}>
                      <option value="6">直近6ヶ月</option>
                      <option value="12">直近12ヶ月</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">                
                {loading ? (
                  <Loading />
                ) : (
                  <LineChart categories={labels} foodSeries={foodSeries} drinkSeries={drinkSeries} />
                )}      
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostTrend;
