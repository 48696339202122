import React, { useEffect, useState } from 'react';
import ModalForm from './modal_form';
import axios from "../../../lib/axios";

const Employees = ({ employees, shops }) => {
  const [draftEmployee, setDraftEmployee] = useState(null);

  useEffect(() => {
    const datatable = new DataTable("#staff_datatable", {
      language: {
        "decimal": ".",
        "thousands": ",",
        "sProcessing": "処理中...",
        "sZeroRecords": "データはありません。",
        "sInfo": " _TOTAL_ 件中 _START_ から _END_ まで表示",
        "sInfoEmpty": " 0 件中 0 から 0 まで表示",
        "sInfoFiltered": "（全 _MAX_ 件より抽出）",
        "sInfoPostFix": "",
        "sSearch": "検索:",
        "sUrl": "",
        "oPaginate": {
          "sFirst": "先頭",
          "sLast": "最終"
        }
      },
    });
  }, []);

  const onClickNew = () => {
    setDraftEmployee({
      id: '',
      shop_id: '',
      employee_code: '',
      division_code: '',
      classification: '',
      daily_transportation_expense: 0,
      total_salary: 0,
      prescribed_working_hours: 0,
      deemed_overtime_hours: 0,
    });
  };

  const onClickEdit = (employeeId) => {
    const employee = employees.find((employee) => {
      return employee.id === employeeId;
    });

    if (! employee) {
      return;
    }

    setDraftEmployee({
      ...employee
    });
  };

  const deleteEmployee = (employee) => {
    axios.delete(`/employees/${employee.id}`).then((response) => {
      window.location.reload();
    }).catch((error) => {
      alert('削除に失敗しました');
    });
  };

  const onClickDelete = (employeeId) => {
    const employee = employees.find((employee) => {
      return employee.id === employeeId;
    });

    if (! employee) {
      return;
    }

    Swal.fire({
      text: `従業員コード ${employee.employee_code} の従業員情報を削除します。削除実行してもいいですか？`,
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "削除する",
      cancelButtonText: 'キャンセル',
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: 'btn btn-light-dark'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEmployee(employee);
      }
    });
  };

  const renderTable = () => {
    const rows = employees.map((employee) => {
      return (
        <tr key={`employee-${employee.id}`}>
          <td>{employee.employee_code}</td>
          <td>{employee.division_code}</td>
          <td><div className="badge badge-light fw-bold fs-7">{shops[employee.shop_id]}</div></td>
          <td>
            <div className={`badge ${employee.classification === 'monthly' ? 'badge-dark' : 'badge-light-gray'} fw-bolder`}>
              {employee.classification === 'monthly' ? '月給' : '時給'}
            </div>
          </td>
          <td className="text-end fw-bold">
            {employee.classification === 'monthly' && (
              <>
                {employee.total_salary.toLocaleString()}円
              </>
            )}
          </td>
          <td className="text-end fw-bold">
            {employee.classification === 'monthly' && (
              <>
                {employee.prescribed_working_hours ?? 0}時間
              </>
            )}
          </td>
          <td className="text-end fw-bold">
            {employee.classification === 'monthly' && (
              <>
                {employee.deemed_overtime_hours ?? 0}時間
              </>
            )}
          </td>
          <td className="text-end">
            <button
              className="btn btn-sm btn-icon btn-light-dark w-35px h-35px"
              data-bs-toggle="modal"
              data-bs-target="#modal_add_staff"
              onClick={() => onClickEdit(employee.id)}
            >
              <i className="ki-duotone ki-pencil fs-3 text-gray-600">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </button>
            <button
              className="btn btn-sm btn-icon btn-light-danger w-35px h-35px modal_delete_staff"
              onClick={() => onClickDelete(employee.id)}
            >
              <i className="ki-duotone ki-trash fs-3">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
              </i>
            </button>
          </td>
        </tr>
      )
    });

    return (
      <table id="staff_datatable" className="table align-middle table-row-bordered gy-3 gs-7">
        <thead>
          <tr className="fw-bold fs-8 text-gray-500">
            <th className="min-w-75px pt-5 pb-4">従業員コード</th>
            <th className="min-w-75px pt-5 pb-4">所属コード</th>
            <th className="min-w-75px pt-5 pb-4">店舗ID</th>
            <th className="min-w-75px pt-5 pb-4">区分</th>
            <th className="min-w-50px pt-5 pb-4 text-end">総支給額</th>
            <th className="min-w-50px pt-5 pb-4 text-end">所定労働時間</th>
            <th className="min-w-50px pt-5 pb-4 text-end">みなし残業時間</th>
            <th className="min-w-75px pt-5 pb-4 text-end">アクション</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="mt-6 mb-4" id="kt_toolbar">
          <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack mobile-flex-column">
            <div className="page-title d-flex align-items-center flex-wrap mb-6 mb-sm-0">
              <h1 className="d-flex text-dark fw-bolder fs-4 align-items-center my-1">従業員管理</h1>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              <div className="w-auto">
                <button
                  type="button"
                  className="btn btn-sm fs-7 fw-bold btn-primary nowrap"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_add_staff"
                  onClick={onClickNew}
                >
                  <i className="ki-duotone ki-plus fs-4 ms-n1 me-0 mb-1"></i>新規従業員情報登録
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <div className="card mb-5 mb-xl-10">
              <div className="card-body p-0 pb-2">
                <div className="table-responsive">
                  {renderTable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalForm shops={shops} employee={draftEmployee} />
    </>
  );
}

export default Employees;
